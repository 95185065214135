body {
  height: 50vh;
  background-color: aliceblue;
}

.App {
  text-align: center;
}

.areas li {
  list-style: none;
}

.App-logo {
  height: 35vmin;
  width: 20vmin;
  color: aqua;
  pointer-events: none;
  text-align: left;
  animation: App-logo-spin infinite 20s linear;
}

.button {
  margin-top: 70px;
  padding: 5px;
  width: 170px;
  border-color: #282c34c9;
  border-width: 4px;
  border-radius: 5px;
  background-color: rgba(0, 255, 255, 0.613);
}

.spots {
  color: #282c34;
  font-style: italic;
}
.App-header {
  background-color: #7283a5;
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: aqua;
}

.App-link {
  color: #61dafb;
}

.App-body {
  height: 50vh;
  width: 80%;
  margin: auto;
  margin-top: 1vh;
  border-style: solid;
  border-radius: 20px;
  border-width: 8px;
  border-color: #282c34c9;
}

.weather {
  padding: 5px;
  font-size: 16px;
  color: rgba(0, 255, 255, 0.613);
}

th {
  width: 400px;
}

h3 {
  padding-bottom: 50px;
}

.weather__description {
  display: flex;
  flex-direction: row;
}
.left {
  width: 80px;
  text-align: left;
}

.right {
  width: 10%;
  text-align: left;
  
}

.copyright {
  padding: 1vh;
  font-size: 12px;
}
/* On screens that are 992px or less */
@media screen and (max-width: 992px) {
  th {
    width: 250px;
  }
}

/* On screens that are 600px or less */
@media screen and (max-width: 600px) {
  th {
    width: 150px;
    font-size: 16px;
  }
  .weather {
    padding: 2px;
    font-size: 10px;
  }
  .App-body,
  body {
    font-size: 14px;
  }
  .button {
    margin-top: 30px;
    padding: 2px;
    width: 100px;
    border-color: #282c34c9;
    border-width: 4px;
    border-radius: 5px;
    background-color: rgba(0, 255, 255, 0.613);
    font-size: 10px;
  }
  h3 {
    font-size: 16px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
